import { __makeTemplateObject } from "tslib";
import styled from 'styled-components';
import { media } from '../../utils/GlobalStyle';
export var HeaderWrapper = styled.header(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  width: 100%;\n  height: var(--header-height);\n  z-index: 10;\n\n  ", " {\n    padding-left: var(--sidebar-width);\n  }\n"], ["\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  width: 100%;\n  height: var(--header-height);\n  z-index: 10;\n\n  ", " {\n    padding-left: var(--sidebar-width);\n  }\n"])), media.medium);
export var MainHeader = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background-color: ", ";\n  color: ", ";\n  text-align: center;\n  padding: 12px 16px;\n  font-size: 1.125em;\n  min-height: 52px;\n  display: flex;\n  align-items: center;\n\n  .site-page-title {\n    flex: 1;\n  }\n\n  .logout {\n    line-height: 1;\n    svg {\n      transform: rotate(180deg);\n    }\n  }\n\n  ", " {\n    background-color: ", ";\n    padding: 12px 24px;\n\n    .logout {\n      display: none;\n    }\n  }\n"], ["\n  background-color: ", ";\n  color: ", ";\n  text-align: center;\n  padding: 12px 16px;\n  font-size: 1.125em;\n  min-height: 52px;\n  display: flex;\n  align-items: center;\n\n  .site-page-title {\n    flex: 1;\n  }\n\n  .logout {\n    line-height: 1;\n    svg {\n      transform: rotate(180deg);\n    }\n  }\n\n  ", " {\n    background-color: ", ";\n    padding: 12px 24px;\n\n    .logout {\n      display: none;\n    }\n  }\n"])), function (props) { return props.theme.primaryColor || '#3d4852'; }, function (props) { return props.theme.header.textColor || '#fff'; }, media.medium, function (props) { return props.theme.header.bgColor || '#3d4852'; });
export var SubHeader = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: relative;\n  background-color: #fff;\n  padding: 8px 16px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  min-height: 55px;\n\n  .current-service {\n    display: flex;\n    align-items: center;\n    justify-content: flex-start;\n    max-width: 70%;\n    gap: 6px;\n\n    .service-logo {\n      width: 40px;\n    }\n\n    .service-info {\n      max-width: 89%;\n      margin-bottom: 0;\n      overflow: hidden;\n\n      small {\n        white-space: nowrap;\n        text-overflow: ellipsis;\n        display: block;\n        overflow: hidden;\n      }\n    }\n  }\n\n  ", " {\n    padding: 8px 24px;\n\n    .current-service {\n      .service-info {\n        max-width: 95%;\n      }\n    }\n  }\n"], ["\n  position: relative;\n  background-color: #fff;\n  padding: 8px 16px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  min-height: 55px;\n\n  .current-service {\n    display: flex;\n    align-items: center;\n    justify-content: flex-start;\n    max-width: 70%;\n    gap: 6px;\n\n    .service-logo {\n      width: 40px;\n    }\n\n    .service-info {\n      max-width: 89%;\n      margin-bottom: 0;\n      overflow: hidden;\n\n      small {\n        white-space: nowrap;\n        text-overflow: ellipsis;\n        display: block;\n        overflow: hidden;\n      }\n    }\n  }\n\n  ", " {\n    padding: 8px 24px;\n\n    .current-service {\n      .service-info {\n        max-width: 95%;\n      }\n    }\n  }\n"])), media.small);
export var ServicesItem = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  cursor: pointer;\n  padding: 10px;\n  border: 1px solid ", ";\n  margin: 10px 0;\n  border-radius: ", ";\n  transition: all 200ms;\n  display: flex;\n  gap: 10px;\n  align-items: center;\n  background-color: ", ";\n\n  .service-logo {\n    width: 40px;\n  }\n\n  &:hover {\n    background: #dadada;\n  }\n"], ["\n  cursor: pointer;\n  padding: 10px;\n  border: 1px solid ", ";\n  margin: 10px 0;\n  border-radius: ", ";\n  transition: all 200ms;\n  display: flex;\n  gap: 10px;\n  align-items: center;\n  background-color: ", ";\n\n  .service-logo {\n    width: 40px;\n  }\n\n  &:hover {\n    background: #dadada;\n  }\n"])), function (props) { return props.theme.border.color; }, function (props) { return props.theme.border.radius; }, function (props) {
    return props.isActive ? '#edf7ed' : props.isCancelled ? '#fdecea' : 'transparent';
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
