import { __assign } from "tslib";
import * as React from 'react';
import { useAtom } from 'jotai';
import { useLocation } from 'react-router';
import { servicesAtom, profileAtom, radiusAtom, voipAtom } from '../../stores';
import useFetch from '../../utils/useFetch';
import { onLogout } from '../../utils/authAction';
import { Button, Paragraph } from '../../components/elements';
import { Loading } from '../../components/loading';
import { Modal } from '../../components/modal';
import { LogoutOutlineIcon, PhoneOutlineIcon } from '../../components/icons';
import nbnLogo from '../../components/assets/nbn-logo.png';
import opticommLogo from '../../components/assets/opticomm-logo.png';
import { HeaderWrapper, MainHeader, ServicesItem, SubHeader } from './styles';
export function Header() {
    var location = useLocation();
    var profile = useAtom(profileAtom)[0];
    var _a = useAtom(radiusAtom), _ = _a[0], setRadius = _a[1];
    var _b = useAtom(servicesAtom), services = _b[0], setServices = _b[1];
    var _c = useAtom(voipAtom), voip = _c[0], setVoip = _c[1];
    var _d = useFetch(), state = _d[0], fetch = _d[1];
    var _e = React.useState(false), show = _e[0], setShow = _e[1];
    React.useEffect(function () {
        if (services && services.currentService) {
            var currentService = services.currentService;
            if (currentService && currentService.type.toLowerCase() !== 'voip') {
                fetch.go("/api/v1/account/services/broadband/is-in-radius?serviceId=".concat(currentService.id, "&avcId=").concat(currentService.avcId), 'GET');
            }
            if (currentService && currentService.type.toLowerCase() === 'voip') {
                fetch.go("/api/v1/superloop-group/service-management/voip-services/".concat(currentService.id), 'GET');
            }
        }
    }, [services]);
    React.useEffect(function () {
        if (state.success) {
            var currentService = services.currentService;
            if (currentService && currentService.type.toLowerCase() !== 'voip') {
                setRadius({
                    timeOnline: state.data.timeOnline || '',
                    isConnected: state.data.isConnected || '',
                    framedIpAddress: state.data.framedIpAddress || '',
                });
            }
            if (currentService && currentService.type.toLowerCase() === 'voip') {
                setVoip(state.data);
            }
            fetch.reset();
        }
    }, [state]);
    var switchService = function (service) {
        setServices(__assign(__assign({}, services), { currentService: service }));
    };
    var displayAddress = function (address) {
        return (React.createElement(React.Fragment, null,
            address.unitNumber,
            " ",
            address.streetNumber,
            " ",
            address.streetName,
            ' ',
            address.streetType,
            " ",
            address.suburb,
            " ",
            address.state,
            " ",
            address.postCode));
    };
    var displayPageName = function () {
        if (location.pathname === '/home') {
            return profile ? profile.accountCode : '';
        }
        if (location.pathname === '/home/usage') {
            return 'Home - Usage';
        }
        if (location.pathname === '/home/plan') {
            return 'Home - Plan';
        }
        if (location.pathname.includes('/home/service-tests')) {
            return 'Home - Service Tests';
        }
        if (location.pathname === '/home/outages') {
            return 'Home - Outages';
        }
        if (location.pathname === '/profile') {
            return 'Profile';
        }
        if (location.pathname === '/profile/edit') {
            return 'Profile - Edit';
        }
        if (location.pathname === '/billing') {
            return 'Billing';
        }
        if (location.pathname === '/billing/payment-details') {
            return 'Billing - Payment Details';
        }
        if (location.pathname === '/billing/payment-extension') {
            return 'Billing - Payment Extension';
        }
        if (location.pathname === '/support') {
            return 'Support';
        }
        if (location.pathname === '/support/support-enquiry') {
            return 'Support - Support Enquiry';
        }
        if (location.pathname === '/support/new-complaint') {
            return 'Support - Complaint Ticket';
        }
        if (location.pathname === '/support/report-fault') {
            return 'Support - Report a Fault';
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(HeaderWrapper, { id: "site-header" },
            React.createElement(MainHeader, null,
                React.createElement("a", { className: "logout", href: "#", onClick: onLogout },
                    React.createElement(LogoutOutlineIcon, null)),
                React.createElement("span", { className: "site-page-title" }, displayPageName())),
            React.createElement(SubHeader, null,
                !services.currentService && React.createElement(Loading, { iconWidth: "55px" }),
                services.list.length > 0 && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "current-service" },
                        services.currentService.type === 'Nbn' && (React.createElement("img", { src: nbnLogo, className: "service-logo" })),
                        services.currentService.type === 'Uniti' && (React.createElement("img", { src: opticommLogo, className: "service-logo" })),
                        services.currentService.type === 'Voip' && (React.createElement(PhoneOutlineIcon, { width: "35" })),
                        React.createElement(Paragraph, { className: "service-info" },
                            services.currentService.code || '',
                            React.createElement("br", null),
                            React.createElement("small", null,
                                services.currentService.type.toLocaleUpperCase(),
                                ":",
                                ' ',
                                displayAddress(services.currentService.address),
                                ' ',
                                services.currentService.avcId
                                    ? " - ".concat(services.currentService.avcId)
                                    : ''))),
                    services.list.length > 1 && (React.createElement(Button, { variant: "info", size: "small", onClick: function () { return setShow(true); } }, "Select Service")))))),
        React.createElement(Modal, { hideCloseIcon: true, open: show, title: "Select Service", footer: React.createElement(React.Fragment, null,
                React.createElement(Button, { variant: "secondary", onClick: function () { return setShow(false); } }, "Close")) }, services.list.map(function (service, idx) { return (React.createElement(ServicesItem, { key: idx, onClick: function () {
                switchService(service);
                setShow(false);
            }, isActive: service.code === services.currentService.code, isCancelled: service.serviceStatus === 'Cancelled' },
            service.type === 'Nbn' && (React.createElement("img", { src: nbnLogo, className: "service-logo" })),
            service.type === 'Uniti' && (React.createElement("img", { src: opticommLogo, className: "service-logo" })),
            service.type === 'Voip' && React.createElement(PhoneOutlineIcon, { width: "35" }),
            React.createElement("div", null,
                service.code || '',
                " ",
                React.createElement("br", null),
                React.createElement("small", null,
                    service.type.toUpperCase(),
                    ": ",
                    displayAddress(service.address),
                    ' ')))); }))));
}
